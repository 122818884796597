
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.day {
  ::v-deep {
    .field {
      .label {
          //noinspection SassScssUnresolvedVariable
          font-size: $size-6;
          //noinspection SassScssUnresolvedVariable
          font-weight: $weight-semibold;
      }
      .checkbox {
        .control-label {
            font-size: $size-normal;
        }
      }
    }
  }
}

.timetable {
  $padding: 0.75rem * 2;
  table-layout: fixed;
  border-spacing: $padding;
  border-collapse: separate;
  margin: (-$padding) (-$padding) 0;
}
